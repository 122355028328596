import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Sync } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import { unitStructureQuery } from 'api/queries';
import { AreaName } from 'domain/constants';
import { LoadingWrapper, SectionTitle } from 'library/components/controls';
import { OperationType } from 'library/models/enums/OperationType';
import {
	calculateDrivelineTotal,
	parseId,
	sortStructure,
} from 'library/utils/helpers/Unit';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';
import { reactPlugin } from 'main/azureInsights/config';
import {
	InstallationHeaderView,
	UnitDrivelineItemView,
} from 'modules/Shared/Components/Unit/';
import { ChangedByInfoDisplay } from 'modules/UnitOverview/Components/ChangedByInfoDisplay';
import { SectionButton } from 'modules/UnitOverview/Components/SectionButton';
import { UnitEditConfigurationButton } from 'modules/UnitOverview/Components/UnitEditConfigurationButton';

const ConfigurationSectionPage = (): JSX.Element => {
	const { t } = useTranslation(['unitCreation', 'unitOverview']);
	const { unitId } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();

	const { api } = useProductCenterApi();

	const parsedUnitId = parseId(unitId);

	const syncUnitMutation = useMutation<void, unknown, number>({
		mutationFn: async (data) => {
			api.forceUnitSync(data);
		},
		onSuccess: () => {
			enqueueSnackbar(t(`unitOverview:force-sync-unit-success`), {
				variant: 'success',
			});
		},
	});

	const { data } = useQuery(unitStructureQuery(parsedUnitId));

	const structure = sortStructure(data);
	const drivelinesTotal = useMemo(
		() => calculateDrivelineTotal(structure?.installations || []),
		[data?.id]
	);

	const syncUnit = async () => {
		try {
			await syncUnitMutation.mutateAsync(parsedUnitId);
		} catch (error) {
			checkConsoleDebuggingReadAccess() && console.error(error);
			enqueueSnackbar(t(`unitOverview:force-sync-unit-error`), {
				variant: 'error',
			});
		}
	};

	return (
		<LoadingWrapper loading={data === null}>
			<SectionTitle
				title={t(
					'unitCreation:unit-information-unit-configuration-title'
				)}>
				<AuthorizeComponent
					areaName={AreaName.ForceSync}
					permission={OperationType.Read}>
					<SectionButton
						text={t('unitOverview:force-sync-unit')}
						icon={<Sync />}
						onClick={syncUnit}
					/>
				</AuthorizeComponent>
				<AuthorizeComponent
					areaName={AreaName.Unit}
					permission={OperationType.Write}>
					<UnitEditConfigurationButton
						evcUnitStatus={data?.evcUnitStatus}
						unitId={parsedUnitId}
					/>
				</AuthorizeComponent>
			</SectionTitle>
			{structure?.installations.map((installation) => (
				<Box key={`installation-${installation.id}`} mt={2}>
					<InstallationHeaderView
						mainSegment={data?.mainSegment ?? null}
						installation={installation}
					/>
					{installation.drivelines.map((driveline) => (
						<UnitDrivelineItemView
							disabled
							unitId={parsedUnitId ?? 0}
							key={`driveline-unit-${driveline.id}`}
							installation={installation}
							driveline={driveline}
							total={drivelinesTotal}
						/>
					))}
				</Box>
			))}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					marginTop: 3,
				}}>
				<ChangedByInfoDisplay
					changedByInfo={data?.configurationChangedBy}
				/>
			</Box>
		</LoadingWrapper>
	);
};

export default withAITracking(
	reactPlugin,
	ConfigurationSectionPage,
	'UnitOverviewConfigurationSectionPage',
	'application-insight-container'
);
