import { NetworkMode, QueryKey, UseQueryOptions } from '@tanstack/react-query';

export const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

type QueryGcTimeOptions<T, K extends QueryKey> = Pick<
	UseQueryOptions<T, Error, T, K>,
	'staleTime' | 'gcTime'
>;

export const cacheTime = <T, K extends QueryKey = QueryKey>(
	minutes?: number
): QueryGcTimeOptions<T, K> => ({
	staleTime: minutes ? minutes * 60 * 1000 : Infinity,
	gcTime: minutes ? minutes * 60 * 1000 : Infinity,
});

export const infinityCacheTime = <T, K extends QueryKey = QueryKey>() =>
	cacheTime<T, K>();

export const offlineMode = {
	networkMode: 'offlineFirst' as NetworkMode,
};
