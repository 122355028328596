import { FileUpload } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import React, { ChangeEventHandler } from 'react';

import { Sxable } from 'library/types';

const Input = styled('input')({
	display: 'none',
});

interface FilesUploadButtonProps extends Sxable {
	disabled?: boolean;
	id: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	multiple?: boolean;
	filesAccepted?: string;
}

export const FileUploadButton = ({
	disabled = false,
	id,
	onChange,
	multiple = false,
	filesAccepted = undefined,
	children,
	sx = [],
}: React.PropsWithChildren<FilesUploadButtonProps>) => (
	<label htmlFor={id}>
		<Input
			id={id}
			multiple={multiple}
			onChange={(event) => {
				onChange(event);
				event.target.value = '';
			}}
			type="file"
			accept={filesAccepted}
		/>
		<Button
			disabled={disabled}
			startIcon={<FileUpload />}
			variant="contained"
			color="secondary"
			size="large"
			component="span"
			sx={[...(Array.isArray(sx) ? sx : [sx])]}
		>
			{children}
		</Button>
	</label>
);
