import {
	Box,
	FormHelperText,
	Skeleton,
	TextFieldProps,
	Typography,
	alpha,
	styled,
} from '@mui/material';

type LabelTextDisplayProps = Pick<TextFieldProps, 'label'>;

interface TextDisplayProps
	extends Pick<TextFieldProps, 'label' | 'color' | 'helperText' | 'sx'>,
		LabelTextDisplayProps {
	value: React.ReactNode;
	/**
	 * Element placed before the children.
	 */
	startIcon?: React.ReactNode;
	/**
	 * Element placed after the children.
	 */
	endIcon?: React.ReactNode;

	loading?: boolean;
}

const Container = styled(Box)(({ theme }) => ({
	'& svg': {
		verticalAlign: 'bottom',
	},
	'.text-display__value': {
		flex: 1,
	},
	'.text-display__start-adornment': {
		alignSelf: 'flex-start',
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(-0.25),
	},
	'.text-display__end-adornment': {
		alignSelf: 'flex-end',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(-0.25),
	},
}));

export const LabelTextDisplay = ({ label }: LabelTextDisplayProps) => (
	<Typography
		className="text-display__label"
		component="label"
		variant="caption"
		fontSize="0.875rem"
		color={(theme) => alpha(theme.palette.primary.main, 0.6)}>
		{label}
	</Typography>
);

export const TextDisplay = ({
	label,
	value,
	color,
	helperText,
	startIcon,
	endIcon,
	loading = false,
	sx = [],
}: TextDisplayProps): JSX.Element => {
	const displayComponent = loading ? <Skeleton variant="rounded" /> : value;

	return (
		<Container className="text-display__root" sx={sx}>
			<LabelTextDisplay label={label} />
			<Typography
				display="flex"
				className="text-display__value-base"
				component="div"
				variant="subtitle1"
				fontWeight="normal"
				alignItems="flex-end"
				color={color ?? 'primary'}>
				{startIcon && (
					<span className="text-display__start-adornment">
						{startIcon}
					</span>
				)}
				<div className="text-display__value">
					{displayComponent != null &&
					typeof displayComponent === 'object' ? (
						displayComponent
					) : (
						<p>
							{Boolean(displayComponent)
								? (displayComponent as string)
								: '\u00A0'}
						</p>
					)}
				</div>
				{endIcon && (
					<span className="text-display__end-adornment">
						{endIcon}
					</span>
				)}
			</Typography>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</Container>
	);
};
