import { ButtonProps, CircularProgress } from '@mui/material';
import { useRef, useState } from 'react';

import { DrivelineSearchResult, useProductCenterApi } from 'api';
import Service from 'assets/volvoIcons/Misc/Service';
import { getLinkCreateNewServiceProtocol } from 'library/components/link';
import { useBlockClick, useDialogState } from 'library/hooks';

import { GenerateProtocolWarningDialog } from './GenerateProtocolWarningDialog';

interface ServiceButtonProps extends Pick<DrivelineSearchResult, 'chassisId'> {
	ButtonElement: React.ElementType;
	buttonText?: string;
	serviceScheduledDate?: Date | null;
	buttonProps?: Omit<
		ButtonProps,
		'disabled' | 'onClick' | 'endIcon' | 'startIcon'
	>;
	openInNewWindow?: boolean;
}

export const ServiceButton = ({
	chassisId,
	serviceScheduledDate,
	ButtonElement,
	buttonText,
	buttonProps,
	openInNewWindow,
}: ServiceButtonProps): JSX.Element => {
	const { open, handleOpen, handleClose } = useDialogState();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const { disabled, handleClick } = useBlockClick();

	const serviceProtocolId = useRef(0);
	const { api } = useProductCenterApi();

	const linkTarget = openInNewWindow ? '_blank' : '_self';

	const handleServiceButtonClicked = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.stopPropagation();

		if (chassisId == null) {
			return;
		}

		event.currentTarget.disabled = true;
		setButtonDisabled(true);
		handleClick();

		(async () => {
			try {
				const response = await api.getLastInitiatedProtocolId({
					chassisId,
				});
				if (response.status == 200 && response.data > 0) {
					serviceProtocolId.current = response.data;
					handleOpen();
				} else {
					window.open(
						getLinkCreateNewServiceProtocol(
							chassisId,
							serviceScheduledDate
						),
						linkTarget
					);
				}
			} finally {
				setButtonDisabled(false);
			}
		})();
	};

	// disable button if additional data is loaded, or if the generate service protocol page will open in the same tab
	const shouldDisableButton =
		buttonDisabled || (!openInNewWindow && disabled);

	return (
		<>
			<ButtonElement
				{...buttonProps}
				disabled={shouldDisableButton}
				onClick={handleServiceButtonClicked}>
				{shouldDisableButton ? (
					<CircularProgress size="1.5rem" />
				) : (
					<Service />
				)}
				{buttonText ? '\u00A0' + buttonText : ''}
			</ButtonElement>
			{open && (
				<GenerateProtocolWarningDialog
					serviceProtocolId={serviceProtocolId.current}
					serviceScheduledDate={serviceScheduledDate}
					chassisId={chassisId as string}
					onClose={handleClose}
					linkTarget={linkTarget}
				/>
			)}
		</>
	);
};
