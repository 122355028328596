import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CurrentLocationBaseDto } from 'api';

import { ServiceInfoStepStateType } from '../Types';
import { GenerateServiceProtocolSlice } from './Types';

export const initialState: GenerateServiceProtocolSlice = {
	activeStep: 0,
	drivelineId: '',
	location: {} as CurrentLocationBaseDto,
	serviceInfo: {} as ServiceInfoStepStateType,
};

export const generateServiceProtocolSlice = createSlice({
	name: 'generateServiceProtocol',
	initialState,
	reducers: {
		nextStep: (state) => {
			state.activeStep += 1;
		},
		previousStep: (state) => {
			if (state.activeStep === 0) {
				return;
			}

			state.activeStep -= 1;
		},
		reset: () => initialState,
		setDrivelineId: (state, { payload }: PayloadAction<number>) => {
			state.drivelineId = payload.toString(); // TODO: check if it's correct to have drivelineId as string
		},
		setLocation: (
			state,
			{ payload }: PayloadAction<CurrentLocationBaseDto>
		) => {
			state.location = payload;
		},
		setServiceInfo: (
			state,
			{ payload }: PayloadAction<ServiceInfoStepStateType>
		) => {
			state.serviceInfo = payload;
		},
	},
});

export const {
	actions: {
		nextStep,
		previousStep,
		reset,
		setDrivelineId,
		setLocation,
		setServiceInfo,
	},
} = generateServiceProtocolSlice;

export default generateServiceProtocolSlice.reducer;
