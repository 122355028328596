import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { ProductIndividualSearchDtoResult, productCenterApi } from 'api';
import {
	DrivelineDto as DrivelineInstallationDto,
	InstallationDto as InstallationDomainDto,
	ProductIndividualDto as ProductIndividualDomainDto,
} from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import { ProductIndividualSearchResult } from 'domain/productIndividual';
import { generateUUID } from 'library/utils/generateUuid';

import { ProductIndividualSearchNoResults } from './Types';

export const validateSearchProductResponse = (
	requestedSerialNumber: string,
	response: AxiosResponse<ProductIndividualSearchDtoResult, any>
): ProductIndividualSearchNoResults | null => {
	if (response.status === 204) {
		return {
			errorMessage: 'unitCreation:api-error-product-not-found',
			serialNumber: null,
		};
	}

	const result = response.data;

	if (
		result.responseType === 'InvalidIndividualStatus' &&
		(result.infoSource === 'PC' || !result.infoSource)
	) {
		return {
			errorMessage: 'unitCreation:api-error-product-status-invalid',
			serialNumber: requestedSerialNumber,
		};
	} else if (
		result.responseType === 'InvalidConfiguration' ||
		result.responseType === 'TemporaryDriveline'
	) {
		return {
			errorMessage:
				'unitCreation:api-error-product-invalid-configuration',
			serialNumber: null,
		};
	} else if (result.partialUnitStructure == null) {
		return {
			errorMessage: 'unitCreation:api-error-product-not-found',
			serialNumber: null,
		};
	}

	return null;
};

export const getProductIndividuals = createAsyncThunk(
	'unit/getProductIndividuals',
	async (serialNumber: string, { rejectWithValue }) => {
		try {
			const response =
				await productCenterApi.searchProductIndividualBySerialNumber({
					serialNumber,
				});

			const validationError = validateSearchProductResponse(
				serialNumber,
				response
			);

			if (validationError) {
				return rejectWithValue(validationError);
			}

			return mapProductIndividualSearchResultApiToSliceType(
				response.data,
				serialNumber
			) as ProductIndividualSearchResult;
		} catch (err) {
			return rejectWithValue({ errorMessage: 'common:error-unexpected' });
		}
	}
);

const mapProductIndividualSearchResultApiToSliceType = (
	result: ProductIndividualSearchDtoResult,
	requestedSerialNumber: string
): ProductIndividualSearchResult => ({
	generatedUuid: generateUUID(),
	requestedSerialNumber: requestedSerialNumber,
	infoSource: result.infoSource!, // will exists, is checked before
	partialUnitStructure: {
		installations: result.partialUnitStructure!.installations.map(
			(i): InstallationDomainDto => ({
				...i,
				sortPosition: 0, // does not exists in the response
				drivelines: i.drivelines.map(
					(d): DrivelineInstallationDto => ({
						...d,
						drivelinePosition: 0, // does not exists in the response
						productIndividuals: d.productIndividuals.map(
							(p): ProductIndividualDomainDto => ({
								...p,
								id: 0, // is not included in response
								generatedUuid: generateUUID(),
							})
						),
					})
				),
			})
		),
	},
	responseType: result.responseType,
});
