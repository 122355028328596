import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Box } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import {
	unitProductInOperationDataQuery,
	unitStructureQuery,
} from 'api/queries';
import { unitKeys } from 'api/queries/UnitQueries';
import { AreaName } from 'domain/constants';
import {
	isProductInOperationButtonDisabled,
	showProductInOperationSection,
} from 'domain/unit/Unit';
import {
	LoadingWrapper,
	PageContentTab,
	SectionTitle,
} from 'library/components/controls';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import { parseId } from 'library/utils/helpers/Unit';
import Authorize from 'main/auth/Authorize';
import { reactPlugin } from 'main/azureInsights/config';
import { productInOperationWizardPageUrl } from 'main/routes/urls/RouteUrls';
import {
	resetState,
	selectProductInOperationFiles,
} from 'modules/ProductInOperation/State';
import {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
} from 'modules/ProductInOperation/State/FilesSlice';
import { AdditionalFileUploadButton } from 'modules/UnitOverview/Components/AdditionalFileUploadButton';
import { AdditionalFilesSection } from 'modules/UnitOverview/Components/AdditionalFilesSection';
import { ChangedByInfoDisplay } from 'modules/UnitOverview/Components/ChangedByInfoDisplay';
import { DateCommentFilesDisplay } from 'modules/UnitOverview/Components/DateCommentFilesDisplay';
import { LocalLinkButton } from 'modules/UnitOverview/Components/LocalLinkButton';
import { IAdditionalFileUploadStateHandlers } from 'modules/UnitOverview/Components/UploadAdditionalFilesDialog';
import { EVCStatusCheck } from 'modules/UnitOverview/UnitInformation/Components/EVCStatusCheck';

const fileStateHandlers: IAdditionalFileUploadStateHandlers = {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
	fileStateSelector: selectProductInOperationFiles,
	reset: resetState,
};

const ProductInOperationSectionPage = (): JSX.Element => {
	const { t } = useTranslation([
		'common',
		'commonWizard',
		'productInOperation',
		'unitOverview',
	]);
	const { unitId } = useParams();

	const unitIdParsed = parseId(unitId);
	const queryClient = useQueryClient();
	const { api } = useProductCenterApi();
	const productInOperationDataQueryResult = useQuery(
		unitProductInOperationDataQuery(api, unitIdParsed)
	);

	const unitQueryResult = useQuery(unitStructureQuery(unitIdParsed));

	const isRegistrationButtonDisabled =
		isProductInOperationButtonDisabled(unitQueryResult.data) ||
		unitQueryResult.isError;

	const isNotConnected =
		!showProductInOperationSection(unitQueryResult.data) ||
		unitQueryResult.isError;

	const handleOnClose = useCallback(
		(isSubmitting: boolean) => {
			if (isSubmitting) {
				queryClient.invalidateQueries({
					queryKey: unitKeys.productInOperationData(unitIdParsed),
				});
			}
		},
		[queryClient, unitIdParsed]
	);

	return (
		<PageContentTab>
			<SectionTitle
				title={t(
					'productInOperation:product-in-operation-title'
				)}></SectionTitle>
			{isNotConnected ? (
				<Authorize
					area={createArea(
						AreaName.ProductInOperation,
						OperationType.Create
					)}
					displayIntermediatePages={false}
					redirectWhenNotAuthorized={false}>
					<EVCStatusCheck unitId={unitIdParsed}>
						<LocalLinkButton
							disabled={isRegistrationButtonDisabled}
							to={generatePath(productInOperationWizardPageUrl, {
								unitId: unitId || null,
							})}>
							{t(
								'unitOverview:register-product-in-operation-button'
							)}
						</LocalLinkButton>
					</EVCStatusCheck>
				</Authorize>
			) : (
				<LoadingWrapper
					loading={productInOperationDataQueryResult.isLoading}>
					{!!productInOperationDataQueryResult.data && (
						<>
							<DateCommentFilesDisplay
								date={
									productInOperationDataQueryResult.data
										.productInOperationDate
								}
								dateLabel={t(
									'commonWizard:product-in-operation-date'
								)}
								comment={
									productInOperationDataQueryResult.data
										.comments
								}
								fileSet={
									productInOperationDataQueryResult.data
										.initialFileSet
								}
							/>
							<Box
								display="flex"
								justifyContent="flex-end"
								mb={6}
								px={8}>
								<ChangedByInfoDisplay
									label={'common:registered-by'}
									changedByInfo={
										productInOperationDataQueryResult.data
											.performedBy
									}
								/>
							</Box>
							<AdditionalFilesSection
								fileSets={
									productInOperationDataQueryResult.data
										.additionalFileSets
								}
								additionalAction={
									<AdditionalFileUploadButton
										handler={fileStateHandlers}
										onClose={handleOnClose}
									/>
								}
							/>
						</>
					)}
				</LoadingWrapper>
			)}
		</PageContentTab>
	);
};

export default withAITracking(
	reactPlugin,
	ProductInOperationSectionPage,
	'UnitOverviewProductInOperationSectionPage',
	'application-insight-container'
);
