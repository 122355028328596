import {
	GridActionsCellItem,
	GridActionsCellItemProps,
} from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { MouseEventHandler, ReactElement, forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { drivelineKeys } from 'api/queries';
import { ParseKeys, TOptions } from 'i18next';
import { CustomWarningDialog } from 'library/components/controls';
import { useDialogState } from 'library/hooks';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';

export type ServiceProtocolActionItemType = Pick<
	GridActionsCellItemProps,
	'showInMenu'
> & {
	drivelineId: number;
	serviceProtocolId: number;
	apiAction: (id: number) => Promise<unknown>;
	icon: ReactElement;
	confirmationDialogTitle: ParseKeys<
		['commonButton'],
		TOptions,
		'commonButton'
	>;
	confirmationDialogContent: ParseKeys<
		['serviceProtocol'],
		TOptions,
		'serviceProtocol'
	>;
	successfulMessage: ParseKeys<
		['serviceProtocol'],
		TOptions,
		'serviceProtocol'
	>;
};

export const ServiceProtocolActionItem = memo(
	forwardRef<HTMLButtonElement | null, ServiceProtocolActionItemType>(
		(
			{
				showInMenu = false,
				drivelineId,
				serviceProtocolId,
				apiAction,
				icon,
				confirmationDialogTitle,
				confirmationDialogContent,
				successfulMessage,
			},
			ref
		): JSX.Element => {
			const { t } = useTranslation(['commonButton', 'serviceProtocol']);
			const { open, handleOpen, handleClose } = useDialogState();
			const { enqueueSnackbar } = useSnackbar();
			const { checkConsoleDebuggingReadAccess } =
				useCheckConsoleDebuggingReadAccess();
			const queryClient = useQueryClient();

			const serviceProtocolMutation = useMutation<
				unknown,
				unknown,
				number
			>({
				mutationKey: drivelineKeys.serviceProtocols(drivelineId),
				mutationFn: (id) => apiAction(id),
				onSuccess: async (_data, _) => {
					await queryClient.invalidateQueries({
						queryKey: drivelineKeys.serviceProtocols(drivelineId),
					});

					enqueueSnackbar(t(successfulMessage), {
						variant: 'success',
					});

					handleClose();
				},
				onError: (error) => {
					checkConsoleDebuggingReadAccess() && console.error(error);
					enqueueSnackbar((error as Error)?.message, {
						variant: 'error',
						style: { whiteSpace: 'pre-line' },
					});
				},
			});

			const handleCancel = () => {
				serviceProtocolMutation.mutateAsync(serviceProtocolId);
			};

			const handleButtonClicked: MouseEventHandler<
				HTMLButtonElement | HTMLLIElement
			> = (event) => {
				handleOpen();
				event.stopPropagation();
			};

			return (
				<>
					<GridActionsCellItem
						ref={ref}
						icon={icon}
						label={t(confirmationDialogTitle)}
						onClick={handleButtonClicked}
						showInMenu={showInMenu}
					/>
					{open && (
						<CustomWarningDialog
							acceptButtonLabel={t('commonButton:yes')}
							declineButtonLabel={t('commonButton:no')}
							content={t(confirmationDialogContent)}
							disabledAcceptButton={
								serviceProtocolMutation.isPending
							}
							headerText={t(confirmationDialogTitle)}
							isOpen
							onAccept={handleCancel}
							onDecline={handleClose}
						/>
					)}
				</>
			);
		}
	)
);

ServiceProtocolActionItem.displayName = 'ServiceProtocolActionItem';
