import { Button, CircularProgress, Link } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRight } from 'assets/volvoIcons';
import { ServiceProtocolStatus } from 'domain/serviceProtocol';
import { getServiceProtocolDetailsLink } from 'library/components/link';
import { useBlockClick } from 'library/hooks';

export const ServiceProtocolDetailsLink = memo(
	({ id, status }: { id: number; status: ServiceProtocolStatus }) => {
		const { t } = useTranslation(['commonButton']);
		const { disabled, handleClick } = useBlockClick();

		return (
			<Button
				component={Link}
				endIcon={
					!disabled ? (
						<ArrowRight />
					) : (
						<CircularProgress size="1em" color="inherit" />
					)
				}
				href={getServiceProtocolDetailsLink(id)}
				disabled={disabled}
				onClick={handleClick}
				sx={{ mr: 2 }}>
				{status === 'Initiated'
					? t('commonButton:edit')
					: t('commonButton:view')}
			</Button>
		);
	}
);

ServiceProtocolDetailsLink.displayName = 'ServiceProtocolDetailsLink';
