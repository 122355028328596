import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProductIndividualsState, UnitOverviewState } from './Types';

const initialState: UnitOverviewState = {
	unitId: 0,
	productIndividuals: {},
};

export const unitOverviewSlice = createSlice({
	name: 'unitOverview',
	initialState,
	reducers: {
		unitIdChanged: (state, { payload }: PayloadAction<number>) => {
			if (!isNaN(payload) && payload >= 0) {
				state.unitId = payload;
			}
		},
		setProductIndividualsCommentsCount: (
			state,
			{ payload }: PayloadAction<ProductIndividualsState>
		) => {
			state.productIndividuals = {
				...payload,

				...state.productIndividuals,
			};
			return state;
		},
		increaseProductIndividualsCommentsCount: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			const commentsCounts =
				state.productIndividuals[payload]?.commentsCount;

			if (commentsCounts >= 0) {
				state.productIndividuals[payload].commentsCount =
					commentsCounts + 1;
			}
			return state;
		},
	},
});

export const {
	increaseProductIndividualsCommentsCount,
	setProductIndividualsCommentsCount,
	unitIdChanged,
} = unitOverviewSlice.actions;

export default unitOverviewSlice.reducer;
