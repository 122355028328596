import { ErrorOutline } from '@mui/icons-material';
import { Box, Link, Paper, Typography } from '@mui/material';
import { isArray } from 'lodash';
import { useState } from 'react';

import { Sxable } from 'library/types';
import { onDownloadLinkClick } from 'library/utils/helpers/FileHelper';
import {
	AuthProviderStorage,
	AuthenticationProvider,
} from 'main/auth/provider';

import { FileIcon } from './FileIcon';

export interface FileDisplayBaseProps extends Sxable {
	name: string;
	type: string;
	failed?: boolean;
	url?: string;
}

export const FileDisplayBase = ({
	name,
	type,
	failed = false,
	url,
	children,
	sx = [],
}: React.PropsWithChildren<FileDisplayBaseProps>): JSX.Element => {
	const [provider] = useState<AuthenticationProvider>(
		AuthProviderStorage.getInstance().getProvider()
	);

	return (
		<Box
			component={Paper}
			elevation={0}
			bgcolor="background.blue"
			position="relative"
			display="flex"
			alignItems="center"
			height={(theme) => theme.spacing(5)}
			sx={isArray(sx) ? sx : [sx]}>
			{failed ? (
				<ErrorOutline color="error" />
			) : (
				<FileIcon mimeType={type} color="secondary" />
			)}
			<Link
				href={url}
				download={name}
				onClick={onDownloadLinkClick(provider, url, name)}>
				<Typography
					color="secondary"
					sx={{
						mx: 2,
						overflowWrap: 'anywhere',
					}}>
					<strong>{name}</strong>
				</Typography>
			</Link>
			{children}
		</Box>
	);
};
