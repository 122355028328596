import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, Typography, useTheme } from '@mui/material';
import { PropsWithChildren, useCallback } from 'react';

import { Sxable } from 'library/types';

import { Accordion, AccordionSummary } from './Accordion';
import { useAccordion } from './useAccordion';

export const AccordionItem = ({
	accordionName,
	header,
	children,
	sx = {},
}: PropsWithChildren<{ accordionName: string; header: string }> & Sxable) => {
	const { checkIfExpanded, onChange } = useAccordion();
	const {
		palette: {
			gray: { main },
		},
	} = useTheme();

	const handleOnChange = useCallback(() => {
		onChange(accordionName);
	}, [accordionName, onChange]);

	return (
		<Accordion
			sx={{
				'&.Mui-expanded': { background: 'white' },

				...sx,
			}}
			expanded={checkIfExpanded(accordionName)}
			onChange={handleOnChange}>
			<AccordionSummary
				sx={{ '&:hover': { background: main } }}
				expandIcon={<ExpandMore />}>
				{header && <Typography>{header}</Typography>}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};
