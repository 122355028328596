import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { productKeys, useQueryProductIndividualComments } from 'api/queries';
import { Comment as CommentType } from 'library/models/Comment';
import { useAppDispatch } from 'library/redux/hooks';
import { increaseProductIndividualsCommentsCount } from 'modules/UnitOverview/State';

export const useProductIndividualComments = (
	serialNumber: string,
	isForeign = false,
	onSuccess = noop,
	onError = noop
) => {
	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation(['unitOverview']);
	const queryClient = useQueryClient();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isAdding, setIsAdding] = useState(false);

	const showErrorSnackbar = (message: string) =>
		enqueueSnackbar(message, {
			variant: 'error',
		});

	const {
		control,
		formState: { isDirty },
		handleSubmit,
		reset,
	} = useForm<Pick<CommentType, 'description'>>({
		defaultValues: {
			description: '',
		},
	});

	const { api } = useProductCenterApi();
	const { data: comments, isInitialLoading } =
		useQueryProductIndividualComments(api, serialNumber, isForeign);
	const isData = !!comments?.length;

	// TODO: in case of refactor create a generic method to make POST via mutation
	const updateCommentMutation = useMutation<number, unknown, CommentType>({
		mutationFn: async ({ description }) => {
			return (
				!isForeign
					? await api.addProductIndividualComment({
							serialNumber,
							description,
						})
					: await api.addForeignProductIndividualComment({
							serialNumber,
							description,
						})
			).data;
		},
		onSuccess: () => {
			reset();
			return queryClient.invalidateQueries({
				queryKey:
					productKeys.productIndividualCommentsAll(serialNumber),
			});
		},
	});

	const submit = handleSubmit(async ({ description }) => {
		setIsSubmitting(true);
		setIsAdding(true);
		try {
			await updateCommentMutation.mutateAsync({
				description,
				modificationDate: new Date(),
				modifiedBy: '',
			});
			dispatch(increaseProductIndividualsCommentsCount(serialNumber));
			onSuccess();
		} catch (error) {
			const { response } = error as AxiosError;

			if (response) {
				showErrorSnackbar(
					t(
						'unitOverview:product-individual-comment-dialog-submit-error'
					)
				);
				onError();
			}
		} finally {
			setIsSubmitting(false);
			setTimeout(() => {
				setIsAdding(false);
			}, 2000);
		}
	});

	return {
		comments,
		control,
		isAdding,
		isData,
		isDirty,
		isInitialLoading,
		isSubmitting,
		submit,
	};
};
