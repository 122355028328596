import {
	EVCNotifiedStatus,
	EVCStatus,
} from 'api/responses/models/Unit/UnitStructure/EVC';
import {
	CampaignType,
	mapCampaignTypeToColor,
	mapCampaignTypeToTranslation,
} from 'domain/campaign';
import { mapEvcStatusToTranslation } from 'domain/evc/EVC';
import { Namespace, TFunction } from 'i18next';
import { CustomAlert, StaticNotification } from 'library/models/Alert';
import { AlertType } from 'library/models/enums/AlertType';

import { setAlert, showStaticNotification, toggleLoader } from './Slice';

export const showLoadingLoader = (): ReturnType<typeof toggleLoader> =>
	toggleLoader('Loading...');

export const showSavingLoader = (): ReturnType<typeof toggleLoader> =>
	toggleLoader('Saving...');

export const showDeletingLoader = (): ReturnType<typeof toggleLoader> =>
	toggleLoader('Deleting...');

export const showUploadingLoader = (): ReturnType<typeof toggleLoader> =>
	toggleLoader('Uploading...');

export const showDownloadingLoader = (): ReturnType<typeof toggleLoader> =>
	toggleLoader('Downloading...');

export const hideLoader = (): ReturnType<typeof toggleLoader> => toggleLoader();

export const showCampaignNotification = (
	campaignType: CampaignType,
	t: TFunction<Namespace>
): ReturnType<typeof showStaticNotification> => {
	const translation = mapCampaignTypeToTranslation(campaignType);

	if (translation) {
		return showStaticNotification({
			message: t(translation),
			severity: mapCampaignTypeToColor(
				campaignType
			) as StaticNotification['severity'],
		});
	}

	throw Error('Campaign notification type not mapped.');
};

export const showEVCNotification = (
	evcStatus: EVCStatus,
	t: TFunction<Namespace>
): ReturnType<typeof showStaticNotification> =>
	showStaticNotification({
		message: t(mapEvcStatusToTranslation(evcStatus as EVCNotifiedStatus)),
		severity: 'info',
	});

export const showSuccessAlert = (
	alert: CustomAlert
): ReturnType<typeof showAlert> => {
	return showAlert(AlertType.Success, alert);
};

export const showErrorAlert = (
	alert: CustomAlert
): ReturnType<typeof showAlert> => {
	return showAlert(AlertType.Error, alert);
};

export const showWarningAlert = (
	alert: CustomAlert
): ReturnType<typeof showAlert> => {
	return showAlert(AlertType.Warning, alert);
};

export const showInfoAlert = (
	alert: CustomAlert
): ReturnType<typeof showAlert> => {
	return showAlert(AlertType.Info, alert);
};

const showAlert = (type: AlertType, customAlert: CustomAlert) =>
	setAlert({
		isVisible: true,
		type: type,
		...customAlert,
	});

export const hideAlert = (): ReturnType<typeof setAlert> =>
	setAlert({
		isVisible: false,
		message: null,
		type: AlertType.Success,
	});
