import { noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';

import { CustomWarningDialog } from 'library/components/controls';

export const useConfirmExit = (confirm: () => boolean, when = true) =>
	useBlocker(when ? confirm : false);

export const usePrompt = (message: string, when = true) => {
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation(['common', 'commonButton']);

	useEffect(() => {
		if (when) {
			window.onbeforeunload = () => message;
		}

		return () => {
			window.onbeforeunload = null;
		};
	}, [message, when]);

	const handleConfirmExit = useCallback(() => {
		setIsOpen(true);
		return true;
	}, []);

	const { proceed = noop, reset = noop } = useConfirmExit(
		handleConfirmExit,
		when
	);

	const handleOnDecline = useCallback(() => {
		setIsOpen(false);
		reset();
	}, [reset]);

	const handleOnAccept = useCallback(() => {
		setIsOpen(false);
		proceed();
	}, [proceed]);

	return (
		<CustomWarningDialog
			isOpen={isOpen}
			headerText={t('common:warning')}
			content={message}
			acceptButtonLabel={t('commonButton:ok')}
			declineButtonLabel={t('commonButton:cancel')}
			onDecline={handleOnDecline}
			onAccept={handleOnAccept}
		/>
	);
};
