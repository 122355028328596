import { styled } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { filter, isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import {
	Dispatch,
	SetStateAction,
	memo,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
	UnitOtherPartDto,
	UpdateOtherPartsForUnitCommand,
	useProductCenterApi,
} from 'api';
import { unitKeys } from 'api/queries/UnitQueries';
import { getOtherPartId } from 'api/responses/models/Unit/UnitOtherPartsDto';
import { isValid } from 'domain/otherPart/validator';
import {
	PageButtons,
	PageContainer,
	PageContent,
	SectionTitle,
} from 'library/components/controls';
import { usePrompt } from 'library/hooks/useRouterNavigation';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';
import {
	OtherPartsSearchBox,
	OtherPartsSearchBoxHandle,
} from 'modules/Shared/Components/Unit/OtherParts/OtherPartsSearchBox';
import { mapToRegistrationPartForSale } from 'modules/UnitCreation/State';
import { InstalledCellChangedEventHandler } from 'modules/UnitCreation/WizardSteps/OtherPartsStep/InstalledCell';

import { ProductUnitDataGrid } from './ProductUnitDataGrid';

const Container = styled(PageContent)(({ theme }) => ({
	display: 'grid',
	gridTemplateAreas: `"addProducts productsGrid"`,
	gridTemplateColumns: '1fr 3fr',
	gridTemplateRows: 'min-content minmax(10px, 1fr)',
	gap: theme.spacing(3, 3),

	[theme.breakpoints.down('md')]: {
		gridTemplateColumns: '1fr',
		gridTemplateAreas: `"info"
		"addProducts"
		"productsGrid"`,
		'&>:nth-of-type(2)': {
			marginTop: theme.spacing(-3),
		},
	},
}));

export const EditProductUnitDataGrid = memo(
	({
		otherParts,
		unitId,
		setOtherProductEdit,
	}: {
		otherParts: UnitOtherPartDto[];
		unitId: number | null;
		setOtherProductEdit: Dispatch<SetStateAction<boolean>>;
	}): JSX.Element => {
		const { t } = useTranslation([
			'common',
			'commonButton',
			'productInOperation',
			'unitCreation',
			'unitOverview',
		]);

		const { api } = useProductCenterApi();

		const { enqueueSnackbar } = useSnackbar();
		const showErrorSnackbar = (message: string) =>
			enqueueSnackbar(message, {
				variant: 'error',
			});

		const apiRef = useGridApiRef();
		const otherPartsSearchBoxRef = useRef<OtherPartsSearchBoxHandle>(null);
		const queryClient = useQueryClient();
		const [originFormData, setOriginFormData] = useState({});
		const [isFormChanged, setIsFormChanged] = useState(false);
		const [rows, setRows] = useState(otherParts);

		const Prompt = usePrompt(
			t('unitOverview:unsaved-warning-dialog-message'),
			isFormChanged
		);
		const { checkConsoleDebuggingReadAccess } =
			useCheckConsoleDebuggingReadAccess();

		useEffect(() => {
			if (apiRef.current) {
				setOriginFormData(
					Array.from(apiRef.current.getRowModels().values())
				);
			}
			// execute this effect only once
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const getCurrentRows = () =>
			Array.from(
				apiRef.current.getRowModels(),
				([_name, value]) => value
			) as UnitOtherPartDto[];

		const handleIsValid = (partNumber: string, serialNumber?: string) => {
			const result = isValid(getCurrentRows(), partNumber, serialNumber);

			if (!result) {
				showErrorSnackbar(
					t('unitCreation:other-parts-product-already-added-message')
				);
			}

			return result;
		};

		const handleAddOtherProduct = (data: UnitOtherPartDto | null) => {
			if (data) {
				const otherProduct = data;

				setRows(() => [
					{ id: getOtherPartId(otherProduct), ...otherProduct },
					...getCurrentRows(),
				]);

				if (otherProduct.serialNumber) {
					setIsFormChanged(true);
				}
			}
		};

		const handleOnInstalledCellChanged: InstalledCellChangedEventHandler = (
			id,
			value
		) => {
			const otherPart = otherParts.find(
				({ partNumber }) => id === partNumber
			);

			setIsFormChanged(
				(!otherPart && !!value) ||
					!isEqual(
						filter(
							Array.from(apiRef.current.getRowModels().values()),
							({ installed }: { installed: number }) => installed
						),
						originFormData
					)
			);
			otherPartsSearchBoxRef.current?.resetError();
		};

		const updatePartsMutation = useMutation<
			void,
			unknown,
			UpdateOtherPartsForUnitCommand
		>({
			mutationFn: async (data) => {
				return (await api.updateOtherPartsForUnit(data)).data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: unitKeys.parts(unitId as number),
				});

				setOtherProductEdit(false);
			},
		});

		const submitOtherPartsInfo = async () => {
			try {
				await updatePartsMutation.mutateAsync({
					unitId: unitId as number,
					otherPartsUpdated: getCurrentRows().map(
						mapToRegistrationPartForSale
					),
				});
			} catch (error) {
				const axiosError = error as AxiosError;
				if (axiosError.response) {
					if (axiosError.response.status === 404) {
						showErrorSnackbar(
							t(
								'unitOverview:other-parts-product-failed-update-message'
							)
						);
					} else {
						checkConsoleDebuggingReadAccess() &&
							console.error(axiosError.response);
					}
				}
			}

			await queryClient.invalidateQueries({
				queryKey: unitKeys.parts(unitId as number),
			});
		};

		return (
			<PageContainer>
				<SectionTitle title={t('unitOverview:other-parts-title')} />
				<Container>
					<OtherPartsSearchBox
						isValid={handleIsValid}
						onAddOtherProduct={handleAddOtherProduct}
						ref={otherPartsSearchBoxRef}
					/>
					<ProductUnitDataGrid
						onInstalledCellChanged={handleOnInstalledCellChanged}
						otherParts={rows}
						isEditMode
						ref={apiRef}
						containerProps={{
							gridArea: 'productsGrid',
						}}
					/>
				</Container>
				<PageButtons
					primaryButtonLabel={t('commonButton:save')}
					primaryButtonAction={submitOtherPartsInfo}
					secondaryButtonLabel={t('commonButton:cancel')}
					secondaryButtonAction={() => setOtherProductEdit(false)}
					isPrimaryButtonSubmitType
					displayLoadingIndicator={updatePartsMutation.isPending}
					primaryButtonDisabled={!isFormChanged}
				/>
				{Prompt}
			</PageContainer>
		);
	}
);
