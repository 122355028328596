import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';

import {
	ApplicationApi,
	IdLabelSortedDto,
	InstallationTypeDto,
	ListItemDto,
	ListItemParentDto,
	MainSegmentNames,
} from 'api';
import { infinityCacheTime } from 'api/ReactQueryOptions';
import { DetailedSegment, InstallationType } from 'domain/unit/enums';

export const productKeys = {
	all: ['product'] as const,

	single: (serialnumber: string) =>
		[...productKeys.all, serialnumber] as const,

	structure: (serialnumber: string) =>
		[
			...productKeys.single(serialnumber),
			'product-individual-structure',
		] as const,

	serialNumber: (serialNumber: string) =>
		[
			...productKeys.single(serialNumber),
			'product-individual-serial-number',
		] as const,

	lineOfBusinessAll: () => [...productKeys.all, 'line-of-business'] as const,
	lineOfBusiness: (mainSegment: string) =>
		[...productKeys.lineOfBusinessAll(), mainSegment] as const,

	unitPurposes: () => [...productKeys.all, 'unit-purposes'] as const,

	detailedSegmentsAll: () =>
		[...productKeys.all, 'detailed-segments'] as const,
	detailedSegments: (mainSegment: string | null) =>
		[...productKeys.detailedSegmentsAll(), mainSegment] as const,

	installationPurposesAll: () =>
		[...productKeys.all, 'installation-purposes'] as const,
	installationPurposes: (detailedSegment: string) =>
		[...productKeys.installationPurposesAll(), detailedSegment] as const,

	operatingProfilesAll: () =>
		[...productKeys.all, 'operating-profiles'] as const,
	operatingProfiles: (installationPurpose: string) =>
		[...productKeys.operatingProfilesAll(), installationPurpose] as const,

	productCategoriesAll: () =>
		[...productKeys.all, 'product-category'] as const,

	productIndividualCommentsAll: (serialNumber: string) =>
		[
			...productKeys.single(serialNumber),
			'comments',
			serialNumber,
		] as const,

	productIndividualCampaigns: (serialNumber: string) =>
		[
			...productKeys.single(serialNumber),
			'campaigns',
			serialNumber,
		] as const,

	productIndividualPolicy: (serialNumber: string) =>
		[...productKeys.single(serialNumber), 'policy', serialNumber] as const,

	productIndividualClaim: (id: number) =>
		[...productKeys.all, 'claim', id] as const,

	productIndividualClaims: (serialNumber: string) =>
		[...productKeys.single(serialNumber), 'claims', serialNumber] as const,

	productIndividualDocuments: (serialNumber: string) =>
		[
			...productKeys.single(serialNumber),
			'documents',
			serialNumber,
		] as const,

	productIndividualLogisticTrails: (serialNumber: string) =>
		[
			...productKeys.single(serialNumber),
			'logistic-trails',
			serialNumber,
		] as const,

	productIndividualConfiguration: (
		serialNumber: string,
		isAftermarketChanges: boolean
	) =>
		[
			...productKeys.single(serialNumber),
			'configuration',
			serialNumber,
			isAftermarketChanges,
		] as const,
	productIndividualEventLog: (serialNumber: string) =>
		[
			...productKeys.single(serialNumber),
			'event-log',
			serialNumber,
		] as const,

	productIndividualPartsList: (serialNumber: string) =>
		[...productKeys.single(serialNumber), 'parts', serialNumber] as const,

	productIndividualTechnicalInfo: (serialNumber: string) =>
		[
			...productKeys.single(serialNumber),
			'technical',
			serialNumber,
		] as const,

	productIndividualEdit: (serialNumber: string) =>
		[...productKeys.single(serialNumber), 'edit'] as const,
};

export const useQueryLineOfBusiness = (
	api: ApplicationApi,
	mainSegment: MainSegmentNames | null
) =>
	useQuery({
		queryKey: productKeys.lineOfBusiness(mainSegment as string),

		queryFn: async () =>
			(
				await api.getLineOfBusinesses({
					mainSegment: mainSegment as MainSegmentNames,
				})
			).data,
		enabled: Boolean(mainSegment),
		...infinityCacheTime<ListItemDto[]>(),
	});

export const useQueryUnitPurposes = (api: ApplicationApi) =>
	useQuery({
		queryKey: productKeys.unitPurposes(),
		queryFn: async () => (await api.getUnitPurposes()).data,
		...infinityCacheTime<ListItemParentDto[]>(),
	});

export const detailedSegmentQuery = (
	api: ApplicationApi,
	mainSegment: MainSegmentNames | null
) => ({
	queryKey: productKeys.detailedSegments(mainSegment),
	queryFn: async () =>
		sortBy(
			(
				await api.getDetailedSegments({
					mainSegment,
				})
			).data,
			['text']
		),
	enabled: Boolean(mainSegment),
	...infinityCacheTime<ListItemDto[]>(),
});

export const installationPurposesQuery = (
	api: ApplicationApi,
	detailedSegment: DetailedSegment | null | undefined
) => ({
	queryKey: productKeys.installationPurposes(
		detailedSegment as DetailedSegment
	),
	queryFn: async () =>
		(
			await api.getInstallationPurposes({
				detailedSegment: detailedSegment as DetailedSegment,
			})
		).data,
	enabled: Boolean(detailedSegment),
	...infinityCacheTime<InstallationTypeDto[]>(),
});

export const foreignProductCategoriesQuery = (api: ApplicationApi) => ({
	queryKey: productKeys.productCategoriesAll(),
	queryFn: async () => {
		const response = await api.getForeignProductCategories();
		return response.data;
	},
	...infinityCacheTime<IdLabelSortedDto[]>(),
});

export const operatingProfilesQuery = (
	api: ApplicationApi,
	installationPurpose: InstallationType | null | undefined
) => ({
	queryKey: productKeys.operatingProfiles(
		installationPurpose as InstallationType
	),
	queryFn: async () =>
		sortBy(
			(
				await api.getOperatingProfiles({
					installationPurpose:
						installationPurpose as InstallationType,
				})
			).data,
			['text']
		),
	enabled: Boolean(installationPurpose),
	...infinityCacheTime<ListItemDto[]>(),
});

export const useQueryProductIndividualComments = (
	api: ApplicationApi,
	serialNumber: string,
	isForeign: boolean
) =>
	useQuery({
		queryKey: productKeys.productIndividualCommentsAll(serialNumber),

		queryFn: async () => {
			const { data = [] } = !isForeign
				? await api.getProductIndividualComments(serialNumber)
				: await api.getForeignProductIndividualComments(serialNumber);

			return data;
		},
	});

export const useProductIndividualQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.structure(serialNumber),
	queryFn: async () =>
		(await api.getProductIndividualBySerialNumber(serialNumber)).data,
});

export const useProductIndividualCampaignsQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualCampaigns(serialNumber),
	queryFn: async () =>
		(await api.getOutstandingCampaignsWithoutPolicy(serialNumber)).data,
});

export const useProductIndividualPolicyQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualPolicy(serialNumber),
	queryFn: async () =>
		(await api.getOutstandingCampaignsOnlyPolicy(serialNumber)).data,
});

export const useProductIndividualClaimQuery = (
	api: ApplicationApi,
	claimJobId: number
) => ({
	queryKey: productKeys.productIndividualClaim(claimJobId),
	queryFn: async () => (await api.getDetailedClaimInfo({ claimJobId })).data,
});

export const useProductIndividualClaimsQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualClaims(serialNumber),
	queryFn: async () =>
		(await api.getClaimHistoryForIndividual(serialNumber)).data,
});

export const useProductIndividualConfigurationQuery = (
	api: ApplicationApi,
	serialNumber: string,
	isAftermarketChanges: boolean
) => ({
	queryKey: productKeys.productIndividualConfiguration(
		serialNumber,
		isAftermarketChanges
	),
	queryFn: async () =>
		(
			await api.getModulesForIndividual(serialNumber, {
				afterMarket: isAftermarketChanges,
			})
		).data,
});

export const useProductIndividualLogisticTrailsQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualLogisticTrails(serialNumber),
	queryFn: async () =>
		(await api.getLogisticTrailForIndividual(serialNumber)).data,
});

export const useProductIndividualEventLogQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualEventLog(serialNumber),
	queryFn: async () =>
		(await api.getEventLogsForIndividual(serialNumber)).data,
});

export const useProductIndividualPartsListQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualPartsList(serialNumber),
	queryFn: async () =>
		(await api.getPartsListForIndividual(serialNumber)).data,
});

export const useProductIndividualTechnicalInfoQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualTechnicalInfo(serialNumber),
	queryFn: async () =>
		(await api.getTechnicalInfoForIndividual(serialNumber)).data,
});

export const useProductIndividualDocumentsQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualDocuments(serialNumber),
	queryFn: async () =>
		(await api.getProductIndividualDocuments(serialNumber)).data,
});

export const useProductIndividualEditQuery = (
	api: ApplicationApi,
	serialNumber: string
) => ({
	queryKey: productKeys.productIndividualEdit(serialNumber),
	queryFn: async () =>
		(await api.getEditProductIndividualBySerialNumber(serialNumber)).data,
});
