import { ParseKeys, TOptions } from 'i18next';

import { EVCNotifiedStatus } from 'api/responses/models/Unit/UnitStructure/EVC';

type EVCStatusTranslations = ParseKeys<
	['unitOverview'],
	TOptions,
	'unitOverview'
>;

const evcStatusToTranslation: {
	[evcStatus in EVCNotifiedStatus]: EVCStatusTranslations;
} = {
	NotRegisterd: 'unitOverview:evc-control-system-status-not-registered',
	Updated: 'unitOverview:evc-control-system-status-update-blocked',
	UpdateBlocked: 'unitOverview:evc-control-system-status-update-blocked',
	RegistrationIncorrect:
		'unitOverview:evc-control-system-status-registration-incorrect',
};

export const mapEvcStatusToTranslation = (
	evcStatus: EVCNotifiedStatus
): EVCStatusTranslations => evcStatusToTranslation[evcStatus];
