import { minBy, reduce, unionBy } from 'lodash';

import { DrivelineSubSystem, ProductIndividualItemPurpose } from 'api';
import { byDrivelinePosition } from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';
import { InstallationSubSystem } from 'api/responses/models/Unit/UnitStructure/InstallationSubSystem';
import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import {
	DetailedSegment,
	InstallationType,
	hasDrivelinePositions,
} from 'domain/unit';
import { drivelineWithControlSystemOnTop } from 'domain/unit/drivelineControlSystemSort';

import { sortByPosition } from './Installation';

const findDrivelineWithLowestPosition = (drivelines: DrivelineSubSystem[]) =>
	minBy(drivelines, ({ position }) => position);

const findDrivelinePosition = (
	driveline: DrivelineSubSystem[],
	maxNumberOfPosition: number
) =>
	findDrivelineWithLowestPosition(driveline)?.position || maxNumberOfPosition;

const sortByDrivelinePosition = (installations: InstallationSubSystem[]) => {
	const maxNumberOfPosition = reduce(
		installations,
		(result, { drivelines }) => result + drivelines.length,
		0
	);

	return installations.sort(
		({ drivelines: drivelinesA }, { drivelines: drivelinesB }) =>
			findDrivelinePosition(drivelinesA, maxNumberOfPosition) -
			findDrivelinePosition(drivelinesB, maxNumberOfPosition)
	);
};

const sortDrivelinesPerPositionWithinMPPurpose = (
	installations: InstallationSubSystem[]
): InstallationSubSystem[] => {
	const installationsWithDrivelinePosition = installations.filter(
		({ detailedSegment, purpose }) =>
			hasDrivelinePositions(detailedSegment, purpose)
	);

	const sorted = sortByDrivelinePosition(installationsWithDrivelinePosition);

	return unionBy(sorted, installations, 'id');
};

export type InstallationTypeCount = {
	[key in Partial<InstallationType>]: number;
};
export type DrivelineTotalCountPerType = {
	[key in Partial<DetailedSegment>]: InstallationTypeCount;
};

export const parseId = (unitId?: string) =>
	unitId ? parseInt(unitId, 10) : -1;

export const getProductIndividualCommentsCount = (
	products: ProductIndividualItemPurpose[]
) =>
	reduce(
		products,
		(acc, { commentsCount = 0, serialNumber }) =>
			serialNumber ? { ...acc, [serialNumber]: { commentsCount } } : acc,
		{}
	);

export const calculateDrivelineTotal = (
	installations: InstallationSubSystem[]
): InstallationTypeCount =>
	reduce(
		installations,
		(current, { drivelines: { length }, purpose }) => {
			if (purpose) {
				current[purpose] = (current[purpose] || 0) + length;
			}

			return current;
		},
		{} as InstallationTypeCount
	);

export const calculateDrivelineTotalPerType = (
	installations: InstallationSubSystem[]
): DrivelineTotalCountPerType =>
	reduce(
		installations,
		(current, { detailedSegment, drivelines: { length }, purpose }) => {
			if (detailedSegment && purpose) {
				if (
					!current[detailedSegment] ||
					!current[detailedSegment][purpose]
				) {
					current[detailedSegment] = {
						...current[detailedSegment],
						[purpose]: length,
					} as InstallationTypeCount;
				} else {
					current[detailedSegment][purpose] += length;
				}
			}
			return current;
		},
		{} as DrivelineTotalCountPerType
	);

export const groupDrivelinePerType = (
	installations: InstallationSubSystem[]
): InstallationSubSystem[] => {
	const groupedInstallations: InstallationSubSystem[] = [];
	// a helper map to store installation's index in array by uniq key = segment-purpose-profile
	const typeIndexMap: { [key: string]: number } = {};

	installations.forEach(
		({
			detailedSegment,
			drivelines,
			operatingProfileValue,
			purpose,
			...rest
		}) => {
			const key = `${detailedSegment}-${purpose}-${operatingProfileValue}`;

			if (typeIndexMap[key] !== undefined) {
				groupedInstallations[typeIndexMap[key]].drivelines.push(
					...drivelines
				);
			} else {
				typeIndexMap[key] =
					groupedInstallations.push({
						detailedSegment,
						drivelines: [...drivelines],
						operatingProfileValue,
						purpose,
						...rest,
					}) - 1;
			}
		}
	);

	return groupedInstallations;
};

export const sortStructure = (unit: Unit | undefined) => {
	if (!unit) {
		return unit;
	}

	const { installations, ...rest } = unit;
	// to sort by position drivelines in installations if their purpose is MechanicalPropulsion
	const sortedDrivelinesWithinInstallations =
		sortDrivelinesPerPositionWithinMPPurpose(installations);
	// to sort by position installations in the unit
	const sortedInstallations = sortByPosition(
		sortedDrivelinesWithinInstallations
	);
	// to group by segment, purpose and profile installations - to cover case of inconsistent structure
	const groupedInstallations = groupDrivelinePerType(sortedInstallations);

	return {
		installations: groupedInstallations.map(({ drivelines, ...rest }) => ({
			drivelines: [...drivelines]
				.sort(byDrivelinePosition) // to sort by position drivelines within their installation
				.sort(drivelineWithControlSystemOnTop), // to push EVC installation on the top of structure
			...rest,
		})),
		...rest,
	};
};
