import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
	CreateOrUpdateUnitLocationCommand,
	CurrentLocationBaseDto,
	useProductCenterApi,
} from 'api';
import { unitKeys } from 'api/queries/UnitQueries';
import {
	PageButtons,
	PageContentTab,
	PageFormContainer,
} from 'library/components/controls';
import { usePrompt } from 'library/hooks/useRouterNavigation';
import { useValidationErrorsSnackbar } from 'library/hooks/useValidationErrorsSnackbar';
import { reactPlugin } from 'main/azureInsights/config';
import { OperatingLocationFormType } from 'modules/Shared/Components/OperatingLocation/OperatingLocationForm';
import { OperatingLocationFormContent } from 'modules/Shared/Components/OperatingLocation/OperatingLocationFormContent';

const EditOperatingLocationPage = ({
	unitId,
	currentLocation,
	handleCancel,
}: {
	unitId: number;
	currentLocation: CurrentLocationBaseDto | undefined | null;
	handleCancel: (isActive: boolean) => void;
}): JSX.Element => {
	const { t } = useTranslation(['commonButton', 'common', 'unitOverview']);
	const { api } = useProductCenterApi();

	const queryClient = useQueryClient();
	const { enqueueValidationSnackbar } = useValidationErrorsSnackbar();

	const {
		handleSubmit,
		control,
		formState: { isDirty },
	} = useForm<OperatingLocationFormType>({
		defaultValues: {
			country: currentLocation?.country,
			state: currentLocation?.state,
			city: currentLocation?.city,
			machineVesselLocation: currentLocation?.machineVesselLocation,
			zipCode: currentLocation?.zipCode,
			address: currentLocation?.address,
		},
	});

	const Prompt = usePrompt(
		t('unitOverview:unsaved-warning-dialog-message'),
		isDirty
	);

	const operatingLocationMutation = useMutation<
		false | void,
		unknown,
		CreateOrUpdateUnitLocationCommand
	>({
		mutationFn: async (formData) => {
			try {
				const { data } = await api.createOrUpdateUnitLocation(formData);
				return data;
			} catch (error) {
				enqueueValidationSnackbar(error as AxiosError);

				return false;
			}
		},
		onSuccess: async (_data, variables) => {
			// Invalidate and refetch
			if (variables.unitId) {
				await queryClient.invalidateQueries({
					queryKey: unitKeys.single(variables.unitId),
				});
			}

			handleCancel(false);
		},
	});

	const handleFormSubmit = handleSubmit(
		(location: OperatingLocationFormType) => {
			operatingLocationMutation.mutateAsync({
				location,
				unitId,
			});
		}
	);

	return (
		<PageFormContainer onSubmit={handleFormSubmit} noValidate>
			<PageContentTab>
				<OperatingLocationFormContent control={control} />
			</PageContentTab>
			<PageButtons
				primaryButtonLabel={t('commonButton:save')}
				secondaryButtonLabel={t('commonButton:cancel')}
				secondaryButtonAction={() => handleCancel(false)}
				isPrimaryButtonSubmitType
				displayLoadingIndicator={operatingLocationMutation.isPending}
				primaryButtonDisabled={!isDirty}
			/>
			{Prompt}
		</PageFormContainer>
	);
};

export default withAITracking(
	reactPlugin,
	EditOperatingLocationPage,
	'UnitOverviewEditOperatingLocationPage',
	'application-insight-container'
);
