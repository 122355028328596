import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
	UnitMeasurement,
	convertWeightToTonnes,
} from 'api/responses/models/Unit/UnitMeasurement';
import { TextDisplay } from 'library/components';
import { joinTwoValuesWithSpace } from 'library/formatters';
import { RadioGroupDisplay } from 'modules/UnitOverview/Components/RadioGroupDisplay';

import { DimensionBox } from '../Components/DimensionBox';
import { IdentifiersContainer } from '../Components/IdentifiersContainer';
import { dimensionTypes } from '../Components/dimensionTypes';

export const DimensionsViewSection = ({
	measurements,
}: {
	measurements: UnitMeasurement | null | undefined;
}) => {
	const { t } = useTranslation('unitCreation');
	const dimensionTypesMemoized = useMemo(() => dimensionTypes(t), [t]);

	const { weight, unitOfWeight } = convertWeightToTonnes(
		measurements?.weight,
		measurements?.unitOfWeight
	);

	return (
		<IdentifiersContainer
			variant="bottomBorder"
			title={t('unit-information-dimensions-title')}
			subtitle={
				<RadioGroupDisplay
					row
					value={measurements?.weightType}
					radioLabelsList={dimensionTypesMemoized}
					sx={{ pl: 1 }}
				/>
			}>
			<DimensionBox>
				<TextDisplay
					label={t('unit-information-length-label')}
					value={joinTwoValuesWithSpace(
						measurements?.overallLength,
						measurements?.unitOfLength
					)}
				/>
			</DimensionBox>
			<DimensionBox>
				<TextDisplay
					label={t('unit-information-width-label')}
					value={joinTwoValuesWithSpace(
						measurements?.width,
						measurements?.unitOfWidth
					)}
				/>
			</DimensionBox>
			<DimensionBox>
				<TextDisplay
					label={t('unit-information-displacement-label')}
					value={joinTwoValuesWithSpace(weight, unitOfWeight)}
				/>
			</DimensionBox>
		</IdentifiersContainer>
	);
};
