import { Box, Switch, Typography, useTheme } from '@mui/material';
import {
	GridColDef,
	GridColumnVisibilityModel,
	GridToolbarExport,
	GridToolbarProps,
	ToolbarPropsOverrides,
} from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	VariantsComparison,
	getVariantsComparisonList,
} from 'api/responses/models/Unit/VariantsComparison';
import { VariantsComparisonRowModel } from 'api/responses/models/Unit/VariantsComparisonRowModel';
import { IDictionary } from 'api/responses/models/common';
import { PageContent, SectionTitle } from 'library/components/controls';
import { DataGridToolbar } from 'library/components/controls/datagrid/DataGridToolbar';
import { LocalizedDataGrid } from 'library/components/controls/datagrid/LocalizedDataGrid';
import Variables from 'library/utils/themes/Variables';

const INITIAL_VISIBLE_DRIVELINES = 4;
const DRIVELINE_COLUMN_PREFIX = 'column';
const getColumnName = (index: number) => DRIVELINE_COLUMN_PREFIX + index;

export const VariantsDataGrid = ({
	model,
	hideDifferencesOnlySwitch = false,
}: {
	model: VariantsComparison;
	hideDifferencesOnlySwitch?: boolean;
}): JSX.Element => {
	const dataModel = model;
	const { t } = useTranslation('unitOverview');
	const theme = useTheme<Variables>();
	const [differencesOnly, setDifferencesOnly] = useState(false);
	const handleSwitchChange = (_event: unknown, checked: boolean) =>
		setDifferencesOnly(checked);

	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>(() => ({
			variantDescription: true,
			descriptionVariantFamily: true,
			functionGroup: true,
			...getInitialVisibleDrivelinesColumns(
				dataModel.columnNameWithDrivelineColumnsIndex
			),
		}));

	const columns: GridColDef<VariantsComparisonRowModel>[] = useMemo(
		() => [
			{
				field: 'variantDescription',
				headerName: t('description-variant'),
				headerAlign: 'left',
				minWidth: 300,
				flex: 2,
			},
			{
				field: 'descriptionVariantFamily',
				headerName: t('description-variant-family'),
				headerAlign: 'left',
				minWidth: 250,
				flex: 2,
			},
			{
				field: 'functionGroup',
				headerName: t('function-group'),
				headerAlign: 'left',
				minWidth: 130,
				flex: 1,
			},
			...generateDrivelineColumns(
				dataModel.columnNameWithDrivelineColumnsIndex
			),
		],
		[dataModel.columnNameWithDrivelineColumnsIndex, t]
	);

	const gridRows = getVariantsComparisonList(
		dataModel,
		(_, index) => !columnVisibilityModel[getColumnName(index)],
		differencesOnly
	);

	return (
		<PageContent>
			<SectionTitle title={t('variants-title')}>
				{!hideDifferencesOnlySwitch && (
					<>
						<Switch
							value={differencesOnly}
							onChange={handleSwitchChange}
						/>

						<Typography>
							{t('variants-comparison-only-differences')}
						</Typography>
					</>
				)}
			</SectionTitle>
			<Box height={theme.custom.dataGridHeight}>
				<LocalizedDataGrid
					rows={gridRows}
					columns={columns}
					slots={{
						toolbar: CustomToolbar as Partial<
							GridToolbarProps & ToolbarPropsOverrides
						>['toolbar'],
					}}
					slotProps={{
						toolbar: {
							visibleDrivelines: getVisibleDrivelinesCount(
								columnVisibilityModel
							),
							allDrivelines: Object.keys(
								dataModel.columnNameWithDrivelineColumnsIndex
							).length,
						},
					}}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={(newmodel) => {
						if (isEmpty(newmodel)) {
							const showAllModelFilter = getShowAllColumnsModel(
								dataModel.columnNameWithDrivelineColumnsIndex
							);

							setColumnVisibilityModel({
								...showAllModelFilter,
							});
						} else {
							setColumnVisibilityModel(newmodel);
						}
					}}
					getRowId={(row) => row.symbol}
				/>
			</Box>
		</PageContent>
	);
};

interface CustomToolbarProps extends GridToolbarProps, ToolbarPropsOverrides {
	visibleDrivelines?: number | null;
	allDrivelines?: number | null;
}

// TODO: try to replace with existing custom Toolbar components like CustomConfigurationDataGridToolbar
const CustomToolbar = ({
	visibleDrivelines,
	allDrivelines,
}: CustomToolbarProps) => {
	const { t } = useTranslation('unitOverview');

	return (
		<DataGridToolbar>
			<GridToolbarExport
				printOptions={{ disableToolbarButton: true }}
				csvOptions={{
					delimiter: ';',
					allColumns: true,
					fileName: 'DrivelineVariants',
				}}
			/>
			<Typography marginLeft="auto">
				{`${t(
					'variants-comparison-selected-driveline'
				)} - ${visibleDrivelines}/${allDrivelines}`}
			</Typography>
		</DataGridToolbar>
	);
};

const getInitialVisibleDrivelinesColumns = (
	columnNameWithDrivelineColumnsIndex: IDictionary<number>
): GridColumnVisibilityModel =>
	Object.values(columnNameWithDrivelineColumnsIndex).reduce(
		(prevVal, currVal, index) => ({
			...prevVal,
			[getColumnName(currVal)]: index < INITIAL_VISIBLE_DRIVELINES,
		}),
		{}
	);

const getShowAllColumnsModel = (
	columnNameWithDrivelineColumnsIndex: IDictionary<number>
): GridColumnVisibilityModel =>
	Object.values(columnNameWithDrivelineColumnsIndex).reduce(
		(prevVal, currVal) => ({
			...prevVal,
			[getColumnName(currVal)]: true,
		}),
		{}
	);

const getVisibleDrivelinesCount = (
	columnVisibilityModel: GridColumnVisibilityModel
) =>
	Object.keys(columnVisibilityModel).filter(
		(column) =>
			columnVisibilityModel[column] &&
			column.includes(DRIVELINE_COLUMN_PREFIX)
	).length;

const generateDrivelineColumns = (
	columnNameWithDrivelineColumnsIndex: IDictionary<number>
): GridColDef<VariantsComparisonRowModel>[] =>
	Object.keys(columnNameWithDrivelineColumnsIndex).map((column, index) => ({
		field: getColumnName(index),
		headerName: column,
		headerAlign: 'left',
		minWidth: 160,
		flex: 1,
		type: 'string',
		valueGetter: (_value, { drivelineColumns, symbol }) =>
			drivelineColumns[columnNameWithDrivelineColumnsIndex[column]]
				? symbol
				: null,
	}));
