import { Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UnitApi } from 'api/UnitApi';
import { unitKeys } from 'api/queries/UnitQueries';
import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import { UpdateUnitInfoRequest } from 'api/responses/models/Unit/UpdateUnitInfoRequest';
import { PageButtons, PageFormContainer } from 'library/components/controls';
import { usePrompt } from 'library/hooks/useRouterNavigation';
import { useValidationErrorsSnackbar } from 'library/hooks/useValidationErrorsSnackbar';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';
import { isUnitMarine } from 'modules/Shared/State/unit';
import { mapUnitDtoToUnitDataFormTypes } from 'modules/UnitCreation/State';
import { BrandModelSection } from 'modules/UnitCreation/WizardSteps/UnitInformationStep/Sections/BrandModelSection';
import { DimensionsSection } from 'modules/UnitCreation/WizardSteps/UnitInformationStep/Sections/DimensionsSection';
import { IdentifiersIndustrialSection } from 'modules/UnitCreation/WizardSteps/UnitInformationStep/Sections/IdentifiersIndustrialSection';
import { IdentifiersMarineSection } from 'modules/UnitCreation/WizardSteps/UnitInformationStep/Sections/IdentifiersMarineSection';

import mapUnitInfoFormToRequest from '../Mappers';
import { UnitGeneralSection } from './UnitGeneralSection';

const unitApi = new UnitApi();

export const EditUnitInformation = ({
	unitData,
	onBack,
}: {
	unitData: Unit;
	onBack: () => void;
}): JSX.Element => {
	const { t } = useTranslation([
		'unitCreation',
		'unitOverview',
		'common',
		'commonButton',
	]);
	const { enqueueSnackbar } = useSnackbar();
	const { enqueueValidationSnackbar } = useValidationErrorsSnackbar();
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();
	const { mainSegment } = unitData;

	const isMarine = isUnitMarine(mainSegment);

	const queryClient = useQueryClient();

	const unitInfoMutation = useMutation<
		boolean,
		unknown,
		UpdateUnitInfoRequest
	>({
		mutationFn: async (data) => {
			return (await unitApi.updateUnitInformation(data)).data;
		},
		onSuccess: async (_data, variables) => {
			// Invalidate and refetch
			if (variables.unitId) {
				await queryClient.invalidateQueries({
					queryKey: unitKeys.single(variables.unitId),
				});
			}

			onBack();
		},
	});

	const {
		handleSubmit,
		trigger,
		control,
		formState: { dirtyFields, isDirty },
		resetField,
		setValue,
		watch,
	} = useForm({
		defaultValues: mapUnitDtoToUnitDataFormTypes(unitData),
	});

	const Prompt = usePrompt(
		t('unitOverview:unsaved-warning-dialog-message'),
		isDirty
	);

	const onSubmit = handleSubmit(
		async (data) => {
			try {
				await unitInfoMutation.mutateAsync(
					mapUnitInfoFormToRequest(data, unitData.id)
				);
			} catch (error) {
				checkConsoleDebuggingReadAccess() && console.error(error);

				enqueueValidationSnackbar(error as Error);
			}
		},
		(err) => {
			checkConsoleDebuggingReadAccess() && console.error(err);

			enqueueSnackbar(t('common:validation-error-message'), {
				variant: 'error',
			});
		}
	);

	return (
		<PageFormContainer noValidate onSubmit={onSubmit}>
			<Stack
				direction="column"
				gap={2}
				sx={{
					'.MuiInputBase-root.MuiOutlinedInput-root': {
						backgroundColor: 'background.paper',
					},
				}}>
				<UnitGeneralSection
					consumerLineOfBusiness={watch('lineOfBusiness')}
					control={control}
					mainSegment={mainSegment}
				/>

				{isMarine ? (
					<IdentifiersMarineSection
						control={control}
						trigger={trigger}
						resetField={resetField}
						setValue={setValue}
						dirtyFields={dirtyFields}
					/>
				) : (
					<IdentifiersIndustrialSection control={control} />
				)}
				<BrandModelSection
					control={control}
					dirtyFields={dirtyFields}
					resetField={resetField}
					setValue={setValue}
					mainSegment={mainSegment}
				/>
				{isMarine && <DimensionsSection control={control} />}
			</Stack>
			<PageButtons
				primaryButtonLabel={t('commonButton:save')}
				secondaryButtonLabel={t('commonButton:back')}
				secondaryButtonAction={onBack}
				primaryButtonDisabled={!isDirty}
				isPrimaryButtonSubmitType
			/>
			{Prompt}
		</PageFormContainer>
	);
};
