import { FC, ReactNode, useEffect, useMemo } from 'react';

import { DrivelineViewDto } from 'api';
import { getChassisId } from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';
import { ProductIndividualItemPurpose } from 'api/responses/models/Unit/UnitStructure/ProductIndividualItemPurpose';
import {
	DrivelineContent,
	DrivelineHeader,
	DrivelineWrapper,
} from 'library/components';
import { getLocalizedDateFormat } from 'library/formatters';
import { useAppDispatch } from 'library/redux/hooks';
import { Disable, Readonly, Sxable } from 'library/types';
import { sortByPosition } from 'library/utils/helpers/Installation';
import { getProductIndividualCommentsCount } from 'library/utils/helpers/Unit';
import { ProductIndividualComments } from 'modules/Shared/Components/ProductIndividual/Comments';
import {
	DrivelineTitleLink,
	defaultDrivelineTitleLinkSx,
} from 'modules/UnitCreation/SharedComponents/Driveline/DrivelineTitleLink';
import { setProductIndividualsCommentsCount } from 'modules/UnitOverview/State/Slice';

import { ForeignProductIndividualCard } from '../ProductIndividual/ForeignProductIndividualCard';
import { ProductIndividualCard } from '../ProductIndividual/ProductIndividualCard';

export type ProductIndividualItemPurposeProps = {
	product: ProductIndividualItemPurpose;
};

interface DrivelineItemViewProps extends Disable, Readonly, Sxable {
	driveline: DrivelineViewDto;
	headerActions?: ReactNode;
	installationId?: string;
	ProductIndividualCardActions?: FC<ProductIndividualItemPurposeProps>;
	withLink?: boolean;
}

const DrivelineItemView = ({
	disabled = false,
	driveline,
	headerActions,
	isReadonly = false,
	installationId,
	sx: wrapperSx,
	ProductIndividualCardActions,
	withLink = true,
}: DrivelineItemViewProps): JSX.Element => {
	const dispatch = useAppDispatch();

	const allSortedIndividuals = useMemo(
		() => [
			...sortByPosition(
				driveline.productIndividualSubSystems.flatMap(
					({ productIndividuals }) => productIndividuals
				)
			),
			...sortByPosition(
				driveline.productIndividualSubSystems.flatMap(
					({ foreignProductIndividuals }) => foreignProductIndividuals
				)
			),
		],
		[driveline.productIndividualSubSystems]
	);

	useEffect(() => {
		dispatch(
			setProductIndividualsCommentsCount(
				getProductIndividualCommentsCount(allSortedIndividuals)
			)
		);
	}, [dispatch, allSortedIndividuals]);

	return (
		<DrivelineWrapper sx={wrapperSx}>
			<DrivelineHeader
				title={
					<DrivelineTitleLink
						chassisId={getChassisId(driveline)}
						drivelineId={driveline.id}
						isActive={withLink}
						sx={defaultDrivelineTitleLinkSx}
					/>
				}
				actions={headerActions}
			/>
			<DrivelineContent>
				{allSortedIndividuals.map(
					({
						description,
						designation,
						highestOpenCampaignType,
						latestKnownOperationHoursDate,
						operatingHours,
						productCategory,
						productName,
						partNumber,
						serialNumber,
						id,
						isNonPentaProduct,
						...rest
					}) => {
						const CardComponent = isNonPentaProduct
							? ForeignProductIndividualCard
							: ProductIndividualCard;

						return (
							<CardComponent
								actions={
									<>
										{serialNumber && (
											<ProductIndividualComments
												disabled={disabled}
												drivelineId={driveline.id.toString()}
												generatedUuid={serialNumber}
												installationId={
													installationId || ''
												}
												isForeign={isNonPentaProduct}
												isReadonly={isReadonly}
												productId={serialNumber}
											/>
										)}
										{!isNonPentaProduct &&
											ProductIndividualCardActions && (
												<ProductIndividualCardActions
													product={{
														description,
														designation,
														highestOpenCampaignType,
														latestKnownOperationHoursDate,
														operatingHours,
														productCategory,
														productName,
														partNumber,
														serialNumber,
														isNonPentaProduct,
														id,
														...rest,
													}}
												/>
											)}
									</>
								}
								key={`product-individual-${serialNumber}`}
								productType={productCategory}
								serialNumber={serialNumber ?? ''}
								designation={designation}
								description={description}
								productName={productName}
								productNumber={partNumber}
								operatingHours={operatingHours}
								operatingHoursDate={getLocalizedDateFormat(
									latestKnownOperationHoursDate
								)}
								campaignType={highestOpenCampaignType}
								generatedUuid={`${id}`}
							/>
						);
					}
				)}
			</DrivelineContent>
		</DrivelineWrapper>
	);
};

export default DrivelineItemView;
