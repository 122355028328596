import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Edit } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { unitStructureQuery } from 'api/queries';
import { unitKeys } from 'api/queries/UnitQueries';
import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import { PdfIcon } from 'assets/images/documents';
import { AreaName } from 'domain/constants';
import {
	LoadingWrapper,
	PageContentTab,
	SectionTitle,
} from 'library/components/controls';
import { getUnitPrintPreviewLink } from 'library/components/link';
import { useDialogState } from 'library/hooks';
import { OperationType } from 'library/models/enums/OperationType';
import { parseId } from 'library/utils/helpers/Unit';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import { isUnitMarine } from 'modules/Shared/State/unit';
import {
	resetUnitCreationState,
	selectUnitCreationFiles,
} from 'modules/UnitCreation/State';
import {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
} from 'modules/UnitCreation/State/FilesSlice';
import { AdditionalFileUploadButton } from 'modules/UnitOverview/Components/AdditionalFileUploadButton';
import { AdditionalFilesSection } from 'modules/UnitOverview/Components/AdditionalFilesSection';
import { ChangedByInfoDisplay } from 'modules/UnitOverview/Components/ChangedByInfoDisplay';
import { SectionButton } from 'modules/UnitOverview/Components/SectionButton';
import { IAdditionalFileUploadStateHandlers } from 'modules/UnitOverview/Components/UploadAdditionalFilesDialog';
import { EditUnitInformation } from 'modules/UnitOverview/UnitInformation/CreateEdit/EditUnitInformation';
import { BrandModelViewSection } from 'modules/UnitOverview/UnitInformation/View/BrandModelViewSection';
import { DimensionsViewSection } from 'modules/UnitOverview/UnitInformation/View/DimensionsViewSection';
import { IdentifiersSection } from 'modules/UnitOverview/UnitInformation/View/IdentifiersSection';
import { InformationViewSection } from 'modules/UnitOverview/UnitInformation/View/InformationViewSection';

const fileStateHandlers: IAdditionalFileUploadStateHandlers = {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
	fileStateSelector: selectUnitCreationFiles,
	reset: resetUnitCreationState,
};

const InformationSectionPage = (): JSX.Element => {
	const { open: isEdit, handleClose, handleOpen } = useDialogState(false);
	const { unitId } = useParams();
	const parsedUnitId = parseId(unitId);
	const { data: unitData, isLoading } = useQuery({
		...unitStructureQuery(parsedUnitId as number),
	});
	const { t } = useTranslation(['unitOverview', 'unitCreation']);
	const queryClient = useQueryClient();

	const handleOnClose = useCallback(
		(isSubmitting: boolean) => {
			if (isSubmitting) {
				queryClient.invalidateQueries({
					queryKey: unitKeys.structure(parsedUnitId),
				});
			}
		},
		[queryClient, parsedUnitId]
	);
	return (
		<PageContentTab>
			<LoadingWrapper loading={isLoading}>
				<SectionTitle title={t('unitCreation:unit-information-title')}>
					<AuthorizeComponent
						areaName={AreaName.MaintainCoverage}
						permission={OperationType.Read}>
						<SectionButton
							text={t('unitOverview:action-unit-preview')}
							icon={<PdfIcon />}
							href={getUnitPrintPreviewLink(unitData?.id ?? 0)}
							target="_blank"
						/>
					</AuthorizeComponent>
					<AuthorizeComponent
						areaName={AreaName.Unit}
						permission={OperationType.Write}>
						<SectionButton
							text={t('unitOverview:edit-unit-information-title')}
							icon={<Edit />}
							onClick={handleOpen}
						/>
					</AuthorizeComponent>
				</SectionTitle>
				{!isEdit ? (
					<Stack direction="column" gap={2} my={2}>
						<InformationViewSection unitData={unitData as Unit} />
						<IdentifiersSection unitData={unitData as Unit} />
						<BrandModelViewSection unitData={unitData as Unit} />
						{isUnitMarine(unitData?.mainSegment) && (
							<DimensionsViewSection
								measurements={unitData?.measurement}
							/>
						)}
						<Box sx={{ alignSelf: 'flex-end' }}>
							<ChangedByInfoDisplay
								changedByInfo={unitData?.informationChangedBy}
							/>
						</Box>
						<AdditionalFilesSection
							fileSets={
								unitData?.allFileSets != null
									? unitData.allFileSets
									: []
							}
							additionalAction={
								<AdditionalFileUploadButton
									handler={fileStateHandlers}
									onClose={handleOnClose}
								/>
							}
						/>
					</Stack>
				) : (
					<AuthorizeComponent
						areaName={AreaName.Unit}
						permission={OperationType.Write}>
						<EditUnitInformation
							unitData={unitData as Unit}
							onBack={handleClose}
						/>
					</AuthorizeComponent>
				)}
			</LoadingWrapper>
		</PageContentTab>
	);
};

export default withAITracking(
	reactPlugin,
	InformationSectionPage,
	'UnitOverviewInformationSectionPage',
	'application-insight-container'
);
