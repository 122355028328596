import { Alert, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { unitStructureQuery } from 'api/queries';
import { isControlSystemStatusCorrect } from 'domain/unit';

export const EVCStatusCheck = ({
	children,
	unitId,
}: PropsWithChildren<{ unitId: number }>) => {
	const { t } = useTranslation(['unitOverview']);
	// data should be loaded already
	const { data } = useQuery(unitStructureQuery(unitId));

	return isControlSystemStatusCorrect(data) ? (
		children
	) : (
		<Alert severity="error">
			<Typography>
				{t(
					'unitOverview:message-action-not-allowed-control-system-not-registered'
				)}
			</Typography>
		</Alert>
	);
};
