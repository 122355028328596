import { ArrowBack } from '@mui/icons-material';
import {
	Box,
	Breadcrumbs,
	Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { PropsWithChildren, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ITitleInfoProps {
	title?: string;
	category?: string | string[];
	action?: React.ReactNode;
	displayBackButton?: boolean;
}

const TitleInfo = ({
	title,
	category,
	action,
	displayBackButton = false,
	children,
}: PropsWithChildren<ITitleInfoProps>): JSX.Element => {
	const { t } = useTranslation('commonUnit');
	const navigate = useNavigate();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md')
	);

	const isTitleNotSet = title === undefined;
	title ??= t('unit-title');

	const isCategoryAnArray = Array.isArray(category);
	const toAddTitleArray = isMobile ? [title] : [];
	const breadcrumbsArray = isCategoryAnArray
		? category
		: [category, ...toAddTitleArray];

	const shouldDisplayBigTitle = isCategoryAnArray || !isMobile;

	return (
		<Box
			id="content__header"
			className="content__header"
			component="header"
			sx={(theme) => ({
				display: 'grid',
				gridTemplate: `"title notification actions"`,
				gridTemplateColumns: 'max-content 1fr max-content',
				alignItems: 'center',
				marginTop: 2,
				marginBottom: { xs: 3, sm: 5 },
				'.content__header__desktop-title-wrapper': {
					marginRight: 10,
				},

				'.content__header__notification-wrapper': {
					marginRight: 2,
				},

				'.content__header__no-title': {
					fontStyle: 'italic',
					color: 'grey.600',
				},

				'.content__header__mobile-title-wrapper > .content__header__breadcrumbs .MuiBreadcrumbs-li:last-of-type span':
					{
						color: 'primary.main',
					},

				[theme.breakpoints.down('md')]: {
					marginTop: 1,
					marginBottom: 1,
					gridTemplate: `"title"
									"notification"									
									"actions"`,
					gridTemplateColumns: '1fr',
					gap: 1.5,
					'.content__header__desktop-title-wrapper': {
						marginRight: 2,
					},
					'.content__header__notification-wrapper': {
						marginRight: 0,
					},
					'.content__header__actions': {
						display: 'flex',
						justifyContent: 'center',
					},
				},
			})}>
			<Box
				gridArea="title"
				className={
					!isMobile
						? 'content__header__desktop-title-wrapper'
						: 'content__header__mobile-title-wrapper'
				}>
				<Breadcrumbs
					maxItems={2}
					aria-label="breadcrumb"
					className="content__header__breadcrumbs"
					sx={{
						fontSize: shouldDisplayBigTitle ? '0.8rem' : undefined,
						lineHeight: '1.5rem',
					}}>
					{breadcrumbsArray
						.filter((item) => !!item)
						.map((item, index) =>
							index === 0 && displayBackButton ? (
								<Typography
									key={item}
									component="span"
									onClick={() => navigate(-1)}
									sx={(theme) => ({
										display: 'flex',
										alignItems: 'center',
										color: 'unset',
										height: theme.spacing(3),

										'&:hover': {
											cursor: 'pointer',
											textDecoration: 'underline',
										},
									})}>
									<ArrowBack
										fontSize="small"
										sx={{
											mr: 1,
										}}
									/>
									{item}
								</Typography>
							) : (
								<Typography component="span" key={item}>
									{item}
								</Typography>
							)
						)}
				</Breadcrumbs>
				{shouldDisplayBigTitle && (
					<Typography
						variant="h2"
						component="h1"
						fontSize="2rem"
						className={[
							'content__header__title',
							isTitleNotSet ? 'content__header__no-title' : '',
						].join(' ')}>
						{title}
					</Typography>
				)}
			</Box>
			<Box
				gridArea="notification"
				className="content__header__notification-wrapper">
				{children}
			</Box>
			<Box gridArea="actions" className="content__header__actions">
				{action}
			</Box>
		</Box>
	);
};

export default memo(TitleInfo);
